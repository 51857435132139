/*
* Index
*/
body {
    margin: 0;
    font-family: inherit, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eaeaea !important;
  }
  
  
  .entrar{
    color: #2E333B;
  }
  
  .login-bg .container figure img{
    max-width: 200px;
    height: auto;
  }
  .fab {
    background-color: #0066BF;
    border: #0066BF;
    position: fixed;
    width: 70px;
    height: 70px;
    border-radius: 3rem;
    bottom:1rem;
    right: 1rem;
  }
  
  .fab-tr{
    background-color: #0066BF;
    border: #0066BF;
    position: fixed;
    width: 70px;
    height: 70px;
    border-radius: 3rem;
    top:4rem;
    right: 1rem;
  }
  .nav-top{
    position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 101 !important;
  }
  /* Fin Index*/
  
/*
* Menu
*/
  nav {
    position: fixed;
    width: auto;
    max-width: 300px;
    bottom: 0; top: 0;
    display: block;
    min-height: 300px;
    height: auto;
    color: #fff;
    transition: all 300ms;
    -moz-transition: all 300ms;
    -webkit-transition: all 300ms;
    width: 100%;
    
  }
  nav .vertical-menu hr{opacity: 0.1; border-width: 0.5px;}
  nav ul{width: 90%; padding-inline-start: 0; margin: 3px; height: calc(100% - 20px); }
  nav .vertical-menu-logo{padding: 20px; font-size: 1.3em; position: relative}
  nav .vertical-menu-logo .open-menu-btn{width: 30px; height: max-content; position: absolute; display: block; right: 20px; top: 0; bottom: 0; margin: auto; cursor: pointer;}
  nav .vertical-menu-logo .open-menu-btn hr{margin: 5px 0}
  nav li{ 
    list-style: none;
    padding: 0px 0px; 
    cursor: pointer;
    padding-right: 0px;
  }
  nav li:hover{ color: rgba(75, 105, 176,1); }
  nav li#user-info{position: absolute; bottom: 0; width: 80%;}
  nav li#user-info > span{display: block; float: right; font-size: 0.9em; position: relative; opacity: 0.6;}
  nav li#user-info > span:after{
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    background: green;
    left: -20px; top: 0; bottom: 0;
    margin: auto;
    border-radius: 50%;
  }
  .content-wrapper{
    width: calc(100% - 300px);
    height: 100%;
    position: fixed;
    background: #eaeaea;
    left: 300px;
    padding: 20px;
  }
  .closed-menu .content-wrapper{
    width: 100%;
    left: 50px;
  }
  .content-wrapper{
      transition: all 300ms;
  }
  
  .vertical-menu-wrapper-add {
    box-shadow: 1px 0px 5px #000;
    background: #2D333B;
    z-index: 100;
  }
  
  .vertical-menu-wrapper .vertical-menu-logo div{transition: all 100ms;}
  .closed-menu .vertical-menu-wrapper .vertical-menu-logo div{
    margin-left: -100px;
  }
  .vertical-menu-wrapper .vertical-menu-logo .open-menu-btn{transition: all 300ms;}
  /* .closed-menu .vertical-menu-wrapper .vertical-menu-logo .open-menu-btn{
    left: 7px;
    right: 100%;
  } */
  
  /* .closed-menu .vertical-menu-wrapper ul,.closed-menu .vertical-menu-wrapper hr{margin-left: -300px;} */
  .vertical-menu-wrapper ul, .vertical-menu-wrapper hr{transition: all 100ms;}
  .content-wrapper{background: #ebebeb;}
  .content{
    width: 90%;
    min-height: 100%;
    background: #eaeaea;
    border-radius: 10px;
    padding: 30px;
  }
  
  /* Add */
  nav .vertical-menu li a {
    padding: 10px;
    font-size: .8em;
    display: block;
    color: #FFF;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .ocultar-menu{
    display: none;
  }
   #menu{
    padding-top: 40px;
   }
  #iconoMenu{
    color: #0066BF;
    font-size: 1.6em;
  }
  
  .padding-menu {
    padding-bottom: 62px;
  }
  
  .pocicionar-icono-menu {
    top: 20px !important;
    left: 310px !important;
  }
  /* Font Awesome */
  
  .pull-right {
    float: right;
  }
  
  .cerrarSesion:hover {
    color: #B42708 !important;
  }
  
  .move-menu{
    left: -300px;
  }

  #NombreUsuario {
    color:#0066BF;
}
.text-header{
    padding: 20px;
    background: #2D333B;
}

.link-hover:hover{
  color: #0066BF !important;
}
.link-hover:focus{
  color: #0066BF !important;
}
  /* Fin Menu*/
  

/*
* Bitacora para tablas
*/

.table-hover{
    margin-top: 20px;
}

table.table.table-striped.table-hover{
    font-size: 12px;
}

table thead{
    text-align: center;
}

table tbody .fecha-hora{
    font-size: 10px;
    font-weight: bold;
}
/* Fin Bitacora */

/*
* Tamaño del scroll
*/
/* Desde aquí el scroll de toda la página */
body::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Estilos barra (thumb) de scroll */
  body::-webkit-scrollbar-thumb {
    background: #2D333B;
    border-radius: 4px;
  }
  
  body::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  body::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos track de scroll */
  body::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 2px;
  }
  
  body::-webkit-scrollbar-track:hover,
  body::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  /* Hasta aquí el scroll de toda la página */
  
  /* Desde aquí para quitar el scroll */
  .no-scroll-query::-webkit-scrollbar {
    width: 0px;
  }
  /* Hasta aquí para quitar el scroll */
  /* Desde aquí el scroll de toda la página */
  /* Tamaño del scroll */
  .scroll-query::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Estilos barra (thumb) de scroll */
  .scroll-query::-webkit-scrollbar-thumb {
    background: var(--bright-orange);
    border-radius: 8px;
  }
  
  .scroll-query::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  .scroll-query::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos track de scroll */
  .scroll-query::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 2px;
  }
  
  .scroll-query::-webkit-scrollbar-track:hover,
  .scroll-query::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  /* Hasta aquí el scroll de toda la página */
  
  /* Desde aquí el scroll de los divs */
  /* Tamaño del scroll */
  div::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Estilos barra (thumb) de scroll */
  div::-webkit-scrollbar-thumb {
    background: #2D333B;
    border-radius: 8px;
  }
  
  div::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  div::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos track de scroll */
  div::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 2px;
  }
  
  div::-webkit-scrollbar-track:hover,
  div::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  /* Hasta aquí el scroll de los divs */
  /* Fin Scroll */

/*
* Check de pruebas
*/
  .form-check-input {
    width: 50px !important;
    height: 30px !important;
}
.form-check-input:checked {
    background-color: #32cd32 !important;
    border-color: #32cd32 !important;
}

.form-check.form-switch {
    padding: 15px 0px 20px 50px !important;
}

label.form-check-label {
    padding: 10px 0 0 10px;
    font-size: 12px;
}

.liberarSalida{
    margin-bottom: 10px;
}



.tamañoBoton{
    width: 100px;
}
  /* Fin CheckPruebas */
.fabl{
  border-radius: 3rem;
  text-align: center; 
}
.bg-success{
  background-color: green !important;
  color: white !important;
}
.bg-error{
  background-color: red !important;
  color: white !important;
}
.hover-icon:hover{
  color:var(--secondary-color)
}
.hover-icon-selected{
  color:var(--secondary-color)
}
.right{
  right: 0 !important;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1030;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0deg 0% 100%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:hover {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0deg 0% 100%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:focus {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0deg 0% 100%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.bordersLeftRounded{
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.w-250px{
  width: 135px;
}
.page-item .page-link {
  /* border-top-right-radius: 0.89rem; */
  /* border-bottom-right-radius: 0.89rem; */
}
.page-item .page-link {
  border-radius: 0.89rem !important;
  border-width: 0px;
}
.text-info{
  color: #11a2e7 !important
}

#root > div.pt-5.mt-4 > div > div > div > div > div.card-body {
  max-height: none;
}