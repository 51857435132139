input[type="text"].search {
	width: 90%;
	height: 61px;
	text-align: center;
	margin: 8px 0;
	outline: none;
	padding: 10px 16px;
	transition: 0.3s;
	font-size: .8em;
	border-radius: 50px;
	margin-left: 5%;
	margin-top: 3%;
  }

.buscador {
	margin-top: 25px !important;
}

.buscar-unidad {
	text-align: center;
	box-shadow: -2px 0px 3px -0.4px #c1b9b9 !important;
	border-radius: 50px 0px 0px 50px !important;
}

.selectCedis {
	/* box-shadow: -2px 0px 3px -0.4px #c1b9b9 !important; */
	border-radius: 50px 50px 50px 50px !important;
	text-align: center;
}

.lista-unidad h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad img {
	background-color: #0066BF;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad .col-2 {
	padding-left: 0px;
}

.lista-unidad .svg-inline--fa.fa-chevron-right {
	float: right;
}

.row.lista-unidad {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.lista-unidad .contenedorCarro {
	background: #0066BF;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.inputWithIcon {
	position: relative;
  }
  .inputWithIcon #iconoBuscar {
	position: absolute;
	right: 7%;
	top: 8%;
	padding: 15px 8px;
	color: #2E333B;
	transition: 0.3s;
  }

  .inputWithIcon button {
	position: absolute;
	right: 5%;
	top: 0;
	/* padding: 0px; */
	color: #2E333B;
	transition: 0.3s;
	background-color: transparent;
	border: transparent solid 1px
  }

/*Estilo de componente Formsalida*/

.separacion {
	padding: 5px;
}

@media (min-width:400px) {
	.padding-container {
		padding: 40px;
	}
}

i.icono-cerrar {
	float: right;
	color: #0066BF;
	cursor: pointer;
}

.display-oculto {
	display: none;
}

.card {
	/* box-shadow: 1px 0px 5px #000; */
	/* z-index: 97; */
}

.card-body {
	font-size: 13.5px;
	max-height: 95vh;
	overflow-y: scroll;
}

.card-title {
	font-size: 13px;
	text-align: right;
}

#card-header {
	background: #2D333B;
	color: #fff;
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.btn {
	text-transform: uppercase;
	font-size: .9em;
	padding: 15px 17px;
	border-radius: 3px;
}

.btn-yellow {
	background: #0066BF;
	-webkit-box-shadow: 0px 19px 30px -22px rgba(255, 205, 0, 0.8);
	-moz-box-shadow: 0px 19px 30px -22px rgba(255, 205, 0, 0.8);
	box-shadow: 0px 19px 30px -22px rgba(255, 205, 0, 0.8);
	color: #2E333B;
}

.btn-circle {
	width: 30px;
	height: 30px;
	padding: 6px 0;
	border-radius: 15px;
	text-align: center;
	font-size: 12px;
	line-height: 1.428571429;
}

#sidebarNuevaSalida, #contenedorEnRuta, #contenedorRegresos, #contenedorNotificaciones, #contenedorPruebas, #contenedorDetalle {
	margin: 0;
	width: auto;
	padding: 0;
	font-display: 1.2rem;
	/* height: 100%; */
	max-width: 385px;
	margin-top: 55px;
}
	
.animated {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes fadeInDown {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
  .fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
  }

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
	transform: translate3d(100%, 0, 0);
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
	transform: translate3d(-100%, 0, 0);
}


/*********************************/


/* PROGRAMAR RUTA*/

.lista-unidad-ruta img {
	background-color: #B8E986;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad-ruta .contenedorCarro-ruta {
	background: #B8E986;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.lista-unidad-ruta h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad-ruta .col-2 {
	padding-left: 0px;
}

.lista-unidad-ruta .svg-inline--fa.fa-chevron-right {
	float: right;
}

.row.lista-unidad-ruta {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad-ruta .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.enRuta {
	width: 380px;
	margin-top: 60px;
}

.pruebaSalida{
	font-size: 15px;
}


/*------------Fin programar ruta----------------------------- */

/* Fila de unidades */
.row.unidad-element{
	background-color: white;
	border-radius: 0.5rem;
	
}
/* .row.unidad-element img{
	width: fit-content;
	max-width: 150px;

} */
.row.unidad-element:hover{
	/* background-color: #AAAAAA; */
	-webkit-box-shadow: -5px 6px 10px -5px black;
	-moz-box-shadow: -5px 6px 10px -5px black;
	box-shadow: -5px 6px 10px -5px black;
	transition: 0.3s;
}

/* PROGRAMAR SALIDA*/

.row.lista-unidad-salida {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad-salida .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.lista-unidad-salida img {
	background-color: #C0C0C0;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad-salida .contenedorCarro-salida {
	background: #C0C0C0;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.lista-unidad-salida h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad-salida .col-2 {
	padding-left: 0px;
}

.lista-unidad-salida .svg-inline--fa.fa-chevron-right {
	float: right;
}

.contenedorBotones {
	/* align-items:"center" ; */
	margin-top: 1rem;
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 80px) !important;
}

[type="number"],[type="text"]  {
	width: 100%;
	margin: 8px 0;
	outline: none;
	/* padding: 26px 10px; */
	transition: 0.3s;
	font-size: 1em;
	border: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.control-input{
	height: 40px !important;
	font-size: 15px !important;
	padding: 0px 0px !important;
	text-align: center;
	text-transform: uppercase;
}


/*------------FIN PROGRAMAR SALIDA----------------------------- */

.btn-block {
	display: block;
	width: 90%;
	text-align: center;
	margin-left: 5%;
	border-radius: 50px;
}

.btn-danger-b {
	color: #dc3545 !important;
	background-color: #f0f2f2 !important;
	border-color: #dc3545 !important;
}

.btn-default-g {
	background: #f0f2f2 !important;
	color: #2E333B !important;
	border-color: #b8b8b8 !important;
}


/* --------------------SALIDAS GUARDADAS--------------------*/

.btn-save {
	color: black;
	font-size: 3em;
}

.guardada-icono {
	padding-left: 20px;
}

.lista-unidad-guardada h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad-guardada img {
	background-color: #0066BF;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad-guardada .col-2 {
	padding-left: 0px;
}

.lista-unidad-guardada .svg-inline--fa.fa-chevron-right {
	float: right;
}

.row.lista-unidad-guardada {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad-guardada .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.lista-unidad-guardada .contenedorGuardada {
	background: #fff;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.notification {
	background-color: #0066BF;
	color: black;
	text-decoration: none;
	padding: 15px 26px;
	position: relative;
	display: inline-block;
	border-radius: 50%;
  }
  
  .notification:hover {
	background: yellow;
	/* color: black; */
  }
  
  .notification .badge {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 5px 10px;
	border-radius: 50%;
	background: red;
	color: white;
  }


/* ---------------------------------------- */

.center {
	text-align: center;
	padding: 40px;
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	[class*="col-"] {
		width: 100%;
	}
}

/* .form-select{
	font-size: 13.5px !important;
} */