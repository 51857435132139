/*
* Loader
*/
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ffcd00;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffcd00 transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* Fin Loader */
      
/* Full Loader */
/*
* Loader
*/
    #preloader2 {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 999999;
      background: rgba(46 ,51 ,59 , 0.36);
      /* padding-top: 15%; */
    }
    #preloader2 img{
        max-height: 300px;
        max-width: 400px;
        width: 100%;
    }
    @media (max-width:400px) {
      #preloader2 img{
          margin-top: 70%;
      }
    }
    @media (min-width:401px) and (max-width:830px) {
      #preloader2 img{
          margin-top: 30%;
      }
    }
    @media (min-width:831px) {
      #preloader2 img{
          margin-top: 15%;
      }
    }
     /* Full Loader */