.error{
    color: red;
}


.login-card form .alert-danger {
    text-align: center;
}

#contenedorErrores{
    width: 100%;
    height: 50px;
    margin-top: 40px;
}