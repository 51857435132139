/*
* Loader
*/
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ffcd00;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffcd00 transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  
  @-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  /* Fin Loader */
      
/* Full Loader */
/*
* Loader
*/
    #preloader2 {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 999999;
      background: rgba(46 ,51 ,59 , 0.36);
      /* padding-top: 15%; */
    }
    #preloader2 img{
        max-height: 300px;
        max-width: 400px;
        width: 100%;
    }
    @media (max-width:400px) {
      #preloader2 img{
          margin-top: 70%;
      }
    }
    @media (min-width:401px) and (max-width:830px) {
      #preloader2 img{
          margin-top: 30%;
      }
    }
    @media (min-width:831px) {
      #preloader2 img{
          margin-top: 15%;
      }
    }
     /* Full Loader */
/*
* Index
*/
body {
    margin: 0;
    font-family: inherit, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eaeaea !important;
  }
  
  
  .entrar{
    color: #2E333B;
  }
  
  .login-bg .container figure img{
    max-width: 200px;
    height: auto;
  }
  .fab {
    background-color: #0066BF;
    border: #0066BF;
    position: fixed;
    width: 70px;
    height: 70px;
    border-radius: 3rem;
    bottom:1rem;
    right: 1rem;
  }
  
  .fab-tr{
    background-color: #0066BF;
    border: #0066BF;
    position: fixed;
    width: 70px;
    height: 70px;
    border-radius: 3rem;
    top:4rem;
    right: 1rem;
  }
  .nav-top{
    position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 101 !important;
  }
  /* Fin Index*/
  
/*
* Menu
*/
  nav {
    position: fixed;
    width: auto;
    max-width: 300px;
    bottom: 0; top: 0;
    display: block;
    min-height: 300px;
    height: auto;
    color: #fff;
    transition: all 300ms;
    -moz-transition: all 300ms;
    -webkit-transition: all 300ms;
    width: 100%;
    
  }
  nav .vertical-menu hr{opacity: 0.1; border-width: 0.5px;}
  nav ul{width: 90%; -webkit-padding-start: 0; padding-inline-start: 0; margin: 3px; height: calc(100% - 20px); }
  nav .vertical-menu-logo{padding: 20px; font-size: 1.3em; position: relative}
  nav .vertical-menu-logo .open-menu-btn{width: 30px; height: -webkit-max-content; height: max-content; position: absolute; display: block; right: 20px; top: 0; bottom: 0; margin: auto; cursor: pointer;}
  nav .vertical-menu-logo .open-menu-btn hr{margin: 5px 0}
  nav li{ 
    list-style: none;
    padding: 0px 0px; 
    cursor: pointer;
    padding-right: 0px;
  }
  nav li:hover{ color: rgba(75, 105, 176,1); }
  nav li#user-info{position: absolute; bottom: 0; width: 80%;}
  nav li#user-info > span{display: block; float: right; font-size: 0.9em; position: relative; opacity: 0.6;}
  nav li#user-info > span:after{
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    background: green;
    left: -20px; top: 0; bottom: 0;
    margin: auto;
    border-radius: 50%;
  }
  .content-wrapper{
    width: calc(100% - 300px);
    height: 100%;
    position: fixed;
    background: #eaeaea;
    left: 300px;
    padding: 20px;
  }
  .closed-menu .content-wrapper{
    width: 100%;
    left: 50px;
  }
  .content-wrapper{
      transition: all 300ms;
  }
  
  .vertical-menu-wrapper-add {
    box-shadow: 1px 0px 5px #000;
    background: #2D333B;
    z-index: 100;
  }
  
  .vertical-menu-wrapper .vertical-menu-logo div{transition: all 100ms;}
  .closed-menu .vertical-menu-wrapper .vertical-menu-logo div{
    margin-left: -100px;
  }
  .vertical-menu-wrapper .vertical-menu-logo .open-menu-btn{transition: all 300ms;}
  /* .closed-menu .vertical-menu-wrapper .vertical-menu-logo .open-menu-btn{
    left: 7px;
    right: 100%;
  } */
  
  /* .closed-menu .vertical-menu-wrapper ul,.closed-menu .vertical-menu-wrapper hr{margin-left: -300px;} */
  .vertical-menu-wrapper ul, .vertical-menu-wrapper hr{transition: all 100ms;}
  .content-wrapper{background: #ebebeb;}
  .content{
    width: 90%;
    min-height: 100%;
    background: #eaeaea;
    border-radius: 10px;
    padding: 30px;
  }
  
  /* Add */
  nav .vertical-menu li a {
    padding: 10px;
    font-size: .8em;
    display: block;
    color: #FFF;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .ocultar-menu{
    display: none;
  }
   #menu{
    padding-top: 40px;
   }
  #iconoMenu{
    color: #0066BF;
    font-size: 1.6em;
  }
  
  .padding-menu {
    padding-bottom: 62px;
  }
  
  .pocicionar-icono-menu {
    top: 20px !important;
    left: 310px !important;
  }
  /* Font Awesome */
  
  .pull-right {
    float: right;
  }
  
  .cerrarSesion:hover {
    color: #B42708 !important;
  }
  
  .move-menu{
    left: -300px;
  }

  #NombreUsuario {
    color:#0066BF;
}
.text-header{
    padding: 20px;
    background: #2D333B;
}

.link-hover:hover{
  color: #0066BF !important;
}
.link-hover:focus{
  color: #0066BF !important;
}
  /* Fin Menu*/
  

/*
* Bitacora para tablas
*/

.table-hover{
    margin-top: 20px;
}

table.table.table-striped.table-hover{
    font-size: 12px;
}

table thead{
    text-align: center;
}

table tbody .fecha-hora{
    font-size: 10px;
    font-weight: bold;
}
/* Fin Bitacora */

/*
* Tamaño del scroll
*/
/* Desde aquí el scroll de toda la página */
body::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Estilos barra (thumb) de scroll */
  body::-webkit-scrollbar-thumb {
    background: #2D333B;
    border-radius: 4px;
  }
  
  body::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  body::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos track de scroll */
  body::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 2px;
  }
  
  body::-webkit-scrollbar-track:hover,
  body::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  /* Hasta aquí el scroll de toda la página */
  
  /* Desde aquí para quitar el scroll */
  .no-scroll-query::-webkit-scrollbar {
    width: 0px;
  }
  /* Hasta aquí para quitar el scroll */
  /* Desde aquí el scroll de toda la página */
  /* Tamaño del scroll */
  .scroll-query::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Estilos barra (thumb) de scroll */
  .scroll-query::-webkit-scrollbar-thumb {
    background: var(--bright-orange);
    border-radius: 8px;
  }
  
  .scroll-query::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  .scroll-query::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos track de scroll */
  .scroll-query::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 2px;
  }
  
  .scroll-query::-webkit-scrollbar-track:hover,
  .scroll-query::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  /* Hasta aquí el scroll de toda la página */
  
  /* Desde aquí el scroll de los divs */
  /* Tamaño del scroll */
  div::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Estilos barra (thumb) de scroll */
  div::-webkit-scrollbar-thumb {
    background: #2D333B;
    border-radius: 8px;
  }
  
  div::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  
  div::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos track de scroll */
  div::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 2px;
  }
  
  div::-webkit-scrollbar-track:hover,
  div::-webkit-scrollbar-track:active {
    background: #d4d4d4;
  }
  /* Hasta aquí el scroll de los divs */
  /* Fin Scroll */

/*
* Check de pruebas
*/
  .form-check-input {
    width: 50px !important;
    height: 30px !important;
}
.form-check-input:checked {
    background-color: #32cd32 !important;
    border-color: #32cd32 !important;
}

.form-check.form-switch {
    padding: 15px 0px 20px 50px !important;
}

label.form-check-label {
    padding: 10px 0 0 10px;
    font-size: 12px;
}

.liberarSalida{
    margin-bottom: 10px;
}



.tamañoBoton{
    width: 100px;
}
  /* Fin CheckPruebas */
.fabl{
  border-radius: 3rem;
  text-align: center; 
}
.bg-success{
  background-color: green !important;
  color: white !important;
}
.bg-error{
  background-color: red !important;
  color: white !important;
}
.hover-icon:hover{
  color:var(--secondary-color)
}
.hover-icon-selected{
  color:var(--secondary-color)
}
.right{
  right: 0 !important;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1030;
}

.css-1s2u09g-control {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0deg 0% 100%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:hover {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0deg 0% 100%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:focus {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0deg 0% 100%) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}
.bordersLeftRounded{
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.w-250px{
  width: 135px;
}
.page-item .page-link {
  /* border-top-right-radius: 0.89rem; */
  /* border-bottom-right-radius: 0.89rem; */
}
.page-item .page-link {
  border-radius: 0.89rem !important;
  border-width: 0px;
}
.text-info{
  color: #11a2e7 !important
}

#root > div.pt-5.mt-4 > div > div > div > div > div.card-body {
  max-height: none;
}
input[type="text"].search {
	width: 90%;
	height: 61px;
	text-align: center;
	margin: 8px 0;
	outline: none;
	padding: 10px 16px;
	transition: 0.3s;
	font-size: .8em;
	border-radius: 50px;
	margin-left: 5%;
	margin-top: 3%;
  }

.buscador {
	margin-top: 25px !important;
}

.buscar-unidad {
	text-align: center;
	box-shadow: -2px 0px 3px -0.4px #c1b9b9 !important;
	border-radius: 50px 0px 0px 50px !important;
}

.selectCedis {
	/* box-shadow: -2px 0px 3px -0.4px #c1b9b9 !important; */
	border-radius: 50px 50px 50px 50px !important;
	text-align: center;
}

.lista-unidad h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad img {
	background-color: #0066BF;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad .col-2 {
	padding-left: 0px;
}

.lista-unidad .svg-inline--fa.fa-chevron-right {
	float: right;
}

.row.lista-unidad {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.lista-unidad .contenedorCarro {
	background: #0066BF;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.inputWithIcon {
	position: relative;
  }
  .inputWithIcon #iconoBuscar {
	position: absolute;
	right: 7%;
	top: 8%;
	padding: 15px 8px;
	color: #2E333B;
	transition: 0.3s;
  }

  .inputWithIcon button {
	position: absolute;
	right: 5%;
	top: 0;
	/* padding: 0px; */
	color: #2E333B;
	transition: 0.3s;
	background-color: transparent;
	border: transparent solid 1px
  }

/*Estilo de componente Formsalida*/

.separacion {
	padding: 5px;
}

@media (min-width:400px) {
	.padding-container {
		padding: 40px;
	}
}

i.icono-cerrar {
	float: right;
	color: #0066BF;
	cursor: pointer;
}

.display-oculto {
	display: none;
}

.card {
	/* box-shadow: 1px 0px 5px #000; */
	/* z-index: 97; */
}

.card-body {
	font-size: 13.5px;
	max-height: 95vh;
	overflow-y: scroll;
}

.card-title {
	font-size: 13px;
	text-align: right;
}

#card-header {
	background: #2D333B;
	color: #fff;
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.btn {
	text-transform: uppercase;
	font-size: .9em;
	padding: 15px 17px;
	border-radius: 3px;
}

.btn-yellow {
	background: #0066BF;
	box-shadow: 0px 19px 30px -22px rgba(255, 205, 0, 0.8);
	color: #2E333B;
}

.btn-circle {
	width: 30px;
	height: 30px;
	padding: 6px 0;
	border-radius: 15px;
	text-align: center;
	font-size: 12px;
	line-height: 1.428571429;
}

#sidebarNuevaSalida, #contenedorEnRuta, #contenedorRegresos, #contenedorNotificaciones, #contenedorPruebas, #contenedorDetalle {
	margin: 0;
	width: auto;
	padding: 0;
	font-display: 1.2rem;
	/* height: 100%; */
	max-width: 385px;
	margin-top: 55px;
}
	
.animated {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }

@keyframes fadeInDown {
	from {
	  opacity: 0;
	  -webkit-transform: translate3d(0, -100%, 0);
	  transform: translate3d(0, -100%, 0);
	}
  
	to {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
	  transform: translate3d(0, 0, 0);
	}
  }
  
  .fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
  }

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
		        transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
	-webkit-transform: translate3d(100%, 0, 0);
	        transform: translate3d(100%, 0, 0);
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
	-webkit-transform: translate3d(-100%, 0, 0);
	        transform: translate3d(-100%, 0, 0);
}


/*********************************/


/* PROGRAMAR RUTA*/

.lista-unidad-ruta img {
	background-color: #B8E986;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad-ruta .contenedorCarro-ruta {
	background: #B8E986;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.lista-unidad-ruta h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad-ruta .col-2 {
	padding-left: 0px;
}

.lista-unidad-ruta .svg-inline--fa.fa-chevron-right {
	float: right;
}

.row.lista-unidad-ruta {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad-ruta .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.enRuta {
	width: 380px;
	margin-top: 60px;
}

.pruebaSalida{
	font-size: 15px;
}


/*------------Fin programar ruta----------------------------- */

/* Fila de unidades */
.row.unidad-element{
	background-color: white;
	border-radius: 0.5rem;
	
}
/* .row.unidad-element img{
	width: fit-content;
	max-width: 150px;

} */
.row.unidad-element:hover{
	/* background-color: #AAAAAA; */
	box-shadow: -5px 6px 10px -5px black;
	transition: 0.3s;
}

/* PROGRAMAR SALIDA*/

.row.lista-unidad-salida {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad-salida .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.lista-unidad-salida img {
	background-color: #C0C0C0;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad-salida .contenedorCarro-salida {
	background: #C0C0C0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.lista-unidad-salida h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad-salida .col-2 {
	padding-left: 0px;
}

.lista-unidad-salida .svg-inline--fa.fa-chevron-right {
	float: right;
}

.contenedorBotones {
	/* align-items:"center" ; */
	margin-top: 1rem;
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 80px) !important;
}

[type="number"],[type="text"]  {
	width: 100%;
	margin: 8px 0;
	outline: none;
	/* padding: 26px 10px; */
	transition: 0.3s;
	font-size: 1em;
	border: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.control-input{
	height: 40px !important;
	font-size: 15px !important;
	padding: 0px 0px !important;
	text-align: center;
	text-transform: uppercase;
}


/*------------FIN PROGRAMAR SALIDA----------------------------- */

.btn-block {
	display: block;
	width: 90%;
	text-align: center;
	margin-left: 5%;
	border-radius: 50px;
}

.btn-danger-b {
	color: #dc3545 !important;
	background-color: #f0f2f2 !important;
	border-color: #dc3545 !important;
}

.btn-default-g {
	background: #f0f2f2 !important;
	color: #2E333B !important;
	border-color: #b8b8b8 !important;
}


/* --------------------SALIDAS GUARDADAS--------------------*/

.btn-save {
	color: black;
	font-size: 3em;
}

.guardada-icono {
	padding-left: 20px;
}

.lista-unidad-guardada h6 {
	margin: 0px;
	padding: 16px;
	text-align: left;
	/* font-weight: bold; */
}

.lista-unidad-guardada img {
	background-color: #0066BF;
	width: 80%;
	margin-left: 5px;
}

.lista-unidad-guardada .col-2 {
	padding-left: 0px;
}

.lista-unidad-guardada .svg-inline--fa.fa-chevron-right {
	float: right;
}

.row.lista-unidad-guardada {
	background: #fff;
	margin: 8px 100px;
	border-radius: 7px;
}

.lista-unidad-guardada .col-10 small {
	color: #B0B5B5;
	font-size: 100%;
}

.lista-unidad-guardada .contenedorGuardada {
	background: #fff;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 12.1px 2px;
	border-radius: 7px 0px 0px 7px;
}

.notification {
	background-color: #0066BF;
	color: black;
	text-decoration: none;
	padding: 15px 26px;
	position: relative;
	display: inline-block;
	border-radius: 50%;
  }
  
  .notification:hover {
	background: yellow;
	/* color: black; */
  }
  
  .notification .badge {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 5px 10px;
	border-radius: 50%;
	background: red;
	color: white;
  }


/* ---------------------------------------- */

.center {
	text-align: center;
	padding: 40px;
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	[class*="col-"] {
		width: 100%;
	}
}

/* .form-select{
	font-size: 13.5px !important;
} */

@charset "UTF-8";
/* CSS Document */
body{
  --primary-color:#000000;
  --secondary-color:#0066BF;
}
body.slide-right {
  -webkit-transform: translateX(30vw);
          transform: translateX(30vw);
  overflow: hidden;
}

.hidden {
  display: none !important;
}
#loader {
  height: 1500px;
  width: 1500px;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 999999999999;
}

#loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.login-bg {
  background: url(/static/media/odometo2.902fe9e1.png) no-repeat fixed center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#logo-teleapp {
  background: url(/static/media/logo-TELAPP.178d244c.png) no-repeat fixed center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.pic-profile {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  margin: 0 auto;
}
.pic-profile img {
  width: 100%;
}
.logo-register {
  width: 150px;
  height: 100px;
  margin: 0 auto;
}
.logo-register img {
  width: 100%;
}
.bg-dark {
  padding: 10px;
  background-color: #2d333b;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.divider {
  height: 1px;
  width: 100%;
  background: #eaeaea;
}
.notify {
  font-size: 12px;
  padding-right: 10px;
  padding-top: 5px;
}

.dark {
  color: #2d333b;
}
.bg-light {
  background-color: #f0f2f2;
}
span.badge-dark {
  margin: 5px;
  padding: 10px 14px;
  border-radius: 50%;
  background: #2d333b;
  position: absolute;
  right: 0px;
  top: 12%;
}
span.badge-warning {
  margin: -11px;
  padding: 12px 9px;
  border-radius: 50%;
  background: #df5062;
}
span.badge-back {
  margin: -11px;
  padding: 12px 9px;
  border-radius: 50%;
  background: #b5dee0;
}
span.badge-gray {
  margin: 5px;
  padding: 10px 14px;
  border-radius: 50%;
  background: #c2c2c2;
  position: absolute;
  right: 0px;
  top: 12%;
  box-shadow: 0px 2px 17px #d4d4d4;
}
.icon-dark {
  color: #2d333b;
}

.icon-yellow {
  color: #ffcd00;
  background: none;
  border: none;
}

.arrow-nav {
  float: left;
  margin-left: 10px;
  color: white;
  display: inline-block;
}

.add a {
  color: #2d333b;
  padding: 10px;
  font-size: 0.9em;
}
/* notificaciones */
span.badge-pending-n {
  margin: -11px;
  padding: 12px 9px;
  border-radius: 50%;
  background: #ffcd00;
}
.text-m {
  text-transform: uppercase;
}
.text-alert {
  color: #df5062;
  font-weight: 700;
  font-style: italic;
}
span.badge-warning-n {
  margin: 0px;
  padding: 12px 9px;
  border-radius: 50%;
  background: #df5062;
}
span.badge-danger- {
  margin: -15px;
  margin-top: 20px;
  padding: 27px 5px 30px 0px;
  border-radius: 6% 0% 0% 6%;
  background: #dc3545;
}
.truck-2 {
  width: 50px;
  margin-left: 10px;
}
span.badge-add {
  margin: 5px;
  padding: 10px 12px;
  border-radius: 50%;
  background: #ffcd00;
}
span.badge-pending {
  margin: -17px;
  margin-top: 20px;
  padding: 23px 3px 34px 0px;
  border-radius: 6% 0% 0% 6%;
  background: #ffcd00;
}
.truck {
  width: 50px;
  margin-top: 13px;
  margin-left: 10px;
}
span.badge-success {
  margin: -17px;
  margin-top: 20px;
  padding: 23px 6px 34px 0px;
  border-radius: 6% 0% 0% 6%;
  background: #b8e986;
}
span.check-success {
  margin: -4px;
  padding: 2px 5px;
  border-radius: 50%;
  background: #b8e986;
}

span.check-secondary {
  margin: -4px;
  padding: 2px 5px;
  border-radius: 50%;
  background: gray;
}

.text-header {
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8em;
}
a.validacion-header {
  color: #2d333b;
  text-decoration: none;
}
a.validacion-header:hover {
  color: #2d333b;
}
.unidad {
  background: #fff;
  margin: 8px 0 8px 0;
  font-size: 0.7em;
  height: 70px;
  width: 100%;
  margin-bottom: 0.3em;
  padding: 9px 0px;
  border-radius: 7px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
}
.unidad_validacion {
  background: #f0f2f2;
  margin: 8px 0 8px 0;
  font-size: 1em;
  height: 70px;
  width: 100%;
  margin-bottom: 0.3em;
  padding: 9px 0px;
  border-radius: 7px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
}
.unidad strong {
  font-size: 0.8em;
}
.unidad small {
  color: #b0b5b5;
}
.unidad a {
  color: #2d333b;
  font-size: 0.8em;
}
.validating {
  background: #f0f2f2;
}

.nav li.nav-item {
  position: relative;
  /**border-left: solid 1px #e5e5e5;**/
  border-radius: 23px 24px 0px 0px;
  -moz-border-radius: 23px 24px 0px 0px;
  -webkit-border-radius: 23px 24px 0px 0px;
  border: 0px solid #000000;
  box-shadow: 0px -7px 25px -13px rgba(199, 199, 199, 1);
}
.badge2 {
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 2px 0px 0px 1px;
  border-radius: 50%;
  top: 11%;
  right: 10%;
  font-size: 2em;
  font-weight: 700;
  text-align: center;
}
.notify-warning {
  border-left: solid 20px #dc3545;
}
.badge-grey {
  color: #dc3545;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.ma {
  margin: 1% 0% 0% 5%;
}
.retorno strong {
  font-size: 0.8em;
}
.retorno small {
  color: #b0b5b5;
}
.retorno a {
  color: #2d333b;
  font-size: 0.8em;
}
.retorno a:hover {
  text-decoration: none;
}
.retorno a img {
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  margin: auto 0;
}
.retorno a.center {
  text-align: center;
  align-content: center;
  align-items: center;
  vertical-align: center;
}
#results {
  line-height: 15px;
}
#results .resultado {
  margin-top: 20px;
}
#results .resultado p {
  font-size: 0.8em;
  color: #bfbfbf;
}
#results .resultado p .principal {
  color: #2d333b;
}
.two-fields {
  width: 100%;
}
.two-fields .input-group {
  width: 100%;
}
.two-fields input {
  width: 50% !important;
}

.list {
  list-style: none;
}
.btn {
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 15px 17px;
  border-radius: 3px;
}
.btn-info {
  background-color: #b8e986;
  border-color: #b8e986;
  color: #ffffff;
}

.btn-info:hover {
  color: #fff;
  background-color: #b8e986;
  border-color: #b8e986;
}
.btn-form {
  margin-left: 20px;
  margin-top: 20px;
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.title-nav {
  text-align: center;
  color: white;
}

.input-2 {
  display: inline-block;
}

.time-hour {
  display: inline-block;
  font-weight: 600;
  font-size: 50px;
  background-color: white;
  padding: 10px;
}

.time-day {
  display: inline-block;
  font-size: 25px;
  text-align: center;
  font-weight: 100;
  line-height: normal;
  padding: 10px;
}

.time-year {
  display: inline-block;
  font-size: 40px;
  text-align: center;
  line-height: normal;
  padding: 10px;
}

.row-status {
  padding: 0;
}

.title-status {
  text-transform: uppercase;
  font-size: 11px;
}

.subtitle-status {
  text-transform: uppercase;
  font-size: 9px;
  color: #bbbbbb;
}

.maps {
  position: relative;
}

.icons-maps {
  position: absolute;
  top: 100px;
  right: 20px;
  font-size: 30px;
}

.text-home {
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 60px;
}

.icons-home {
  color: #ffffff;
  float: right;
  margin-bottom: 48px;
  margin-top: 48px;
}

.line-home {
  border-bottom: 1px solid #ffffff;
}

.btn-yellow {
  background: #ffcd00;
  box-shadow: 0px 19px 30px -22px rgba(255, 205, 0, 0.8);
  color: #2e333b;
}

.btn-orange {
  background-color: #f5a623;
}

.text-yellow {
  color: #ffcd00;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1pt;
}

.subtitle-yellow {
  color: #ffcd00;
  text-align: center;
  font-size: 11px;
}

.subtitle {
  text-transform: uppercase;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
}
/**********========= SELECT =========*******/
.styled-select {
  background: url(/static/media/chevron-select.7c1338c0.svg) no-repeat 96% #fff;
  height: 51px;
  overflow: hidden;
  width: 100%;
  font-size: 0.8em;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
  border: none;
  margin: 8px 0;
  transition: 0.3s;
  font-size: 0.7em;
}
option .add {
  background: #000;
}
.styled-select select {
  background: #fff;
  border: none;
  font-size: 12px;
  height: 42px;
  padding: 5px; /* If you add too much padding here, the options won't show in IE */
  width: 100%;
  text-transform: uppercase;
  padding: 26px 16px;
}

.styled-select.slate {
  background: url(/static/media/chevron-select.7c1338c0.svg) no-repeat right center;
  height: 34px;
  width: 240px;
}

.styled-select.slate select {
  border: 1px solid #ccc;
  font-size: 12px;
  height: 34px;
  width: 268px;
}

/* -------------------- Rounded Corners */
.rounded {
  border-radius: 20px;
}

.semi-square {
  border-radius: 3px;
}

/* -------------------- Colors: Background */
.slate {
  background-color: #ddd;
}
.green {
  background-color: #779126;
}
.blue {
  background-color: #2d333b;
}
.yellow {
  background-color: #eec111;
}
.black {
  background-color: #000;
}

/* -------------------- Colors: Text */
.slate select {
  color: #000;
}
.green select {
  color: #fff;
}
.blue select {
  color: #fff;
}
.yellow select {
  color: #000;
}
.black select {
  color: #fff;
}

/* -------------------- Select Box Styles: danielneumann.com Method */
/* -------------------- Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ */
#mainselection select {
  border: 0;
  color: #eee;
  background: transparent;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 10px;
  width: 378px;
  *width: 350px;
  *background: #58b14c;
  -webkit-appearance: none;
}

#mainselection {
  overflow: hidden;
  width: 350px;
  border-radius: 9px 9px 9px 9px;
  box-shadow: 1px 1px 11px #330033;
  background: #58b14c url("http://i62.tinypic.com/15xvbd5.png") no-repeat scroll
    319px center;
}

/* -------------------- Select Box Styles: stackoverflow.com Method */
/* -------------------- Source: http://stackoverflow.com/a/5809186 */
select#soflow,
select#soflow-color {
  -webkit-appearance: button;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  background-image: url(http://i62.tinypic.com/15xvbd5.png),
    -webkit-linear-gradient(#fafafa, #f4f4f4 40%, #e5e5e5);
  background-position: 97% center;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  color: #555;
  font-size: inherit;
  margin: 20px;
  overflow: hidden;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

select#soflow-color {
  color: #fff;
  background-image: url(http://i62.tinypic.com/15xvbd5.png),
    -webkit-linear-gradient(#779126, #779126 40%, #779126);
  background-color: #779126;
  border-radius: 20px;
  padding-left: 15px;
}
/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}
/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/****** END SELECT******/
input {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
  border: none;
}

input[type="text"] {
  width: 100%;
  margin: 8px 0;
  outline: none;
  padding: 26px 10px;
  transition: 0.3s;
  font-size: 1em;
  border: none;
}

input[type="password"] {
  width: 100%;
  margin: 8px 0;
  outline: none;
  padding: 26px 16px;
  transition: 0.3s;
  font-size: 0.8em;
  border: none;
}
input[type="text"].search {
  width: 90%;
  height: 61px;
  text-align: center;
  margin: 8px 0;
  outline: none;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 0.8em;
  border-radius: 50px;
  margin-left: 5%;
  margin-top: 3%;
}
input[type="text"].search-2 {
  width: 95%;
  height: 52px;
  text-align: left;
  margin: 0px 0;
  outline: none;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 0.8em;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  margin-left: 5%;
  margin-top: 3%;
}

.inputWithIcon {
  position: relative;
}
.inputWithIcon i {
  position: absolute;
  right: 7%;
  top: 22%;
  padding: 15px 8px;
  color: #2e333b;
  transition: 0.3s;
}

.inputWithIcon-2 i {
  position: absolute;
  right: 7%;
  top: 15%;
  padding: 10px 8px;
  color: #ffcd00;
  transition: 0.3s;
}
.inputWithIcon-3 i {
  position: absolute;
  right: 7%;
  top: 12%;
  padding: 18px 8px;
  color: #ffcd00;
  transition: 0.3s;
}
.inputWithIcon input[type="text"]:focus + i {
  color: #ffcd00;
}

.inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
}
.text-hour {
  color: #9a9d9f;
  font-size: 80%;
}
.text-nav {
  color: #ffffff;
  text-align: center;
  margin-top: 12px;
}
.text-navy {
  color: #9a9d9f;
}
h6 {
  font: 16px sans-serif;
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: center;
  color: #2e333b;
}
h5 {
  font-size: 16px;
}
h4 {
  font-size: 1.3em;
}
h6.linea {
  position: relative;
  z-index: 1;
  color: #b0b5b5;
}
h6.linea:before {
  border-top: 1px solid #b0b5b5;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 95%;
  z-index: -1;
}
h6.linea span {
  background: #f0f2f2;
  padding: 0 15px;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.description {
  color: #aaa;
}

.imagen-responsive {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.icon-background1 {
  color: #f39200;
}

.icon-background2 {
  color: #b8e986;
}

/***==========COLORS=============****/
.green-circle {
  background: #b8e986;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
}
.white {
  color: #fff;
}
.yellow {
  color: #ffcd00;
}
.green-schedule {
  color: #7ed321;
}
.blue-schedule {
  color: #5ab2c7;
}
.status-green {
  background-color: #b8e986;
  padding: 5px 10px;
  font-size: 11px;
}
.btn-dark {
  background-color: #616c7c;
  border: none;
}
.bg-green {
  height: 60px;
  width: 100%;
  background-color: #b8e986;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
  padding-left: 15px;
}
.bg-red {
  height: 60px;
  width: 100%;
  background: #df5062;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.21);
  padding-left: 15px;
}
/** SIDE NAVBAR*******/

.navbar {
  padding: 15px 10px;
  background: #fff;
  border-top: solid 1px #ffcd00;
  border-radius: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px;
  height: 100vh;
  z-index: 999;
  background: #2d333b;
  color: #fff;
  transition: all 0.3s;
  overflow-y: scroll;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
  left: 0;
}
.profile-info {
  border-bottom: solid 1px #4a4a4a;
  margin: 5px;
}
.profile-info p {
  font-size: 1em;
  color: #ffcd00;
}
.profie-info p small {
  color: #ffffff !important ;
  font-size: 0.7em;
}
.profile-pic {
  width: 100px;
  margin: 0 auto;
}
.profile-pic img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0px auto;
}
#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
#dismiss:hover {
  background: #fff;
  color: #7386d5;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  display: none;
}
#sidebar {
  z-index: 10210;
}

#sidebar .sidebar-header {
  padding: 20px;
  padding-top: 50px;
  background: #2d333b;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 0.8em;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
#sidebar ul li a:hover {
  color: #f4f4f4;
  background: #151a22;
  text-decoration: none;
  transition: All 0.2544s ease;
  -webkit-transition: All 0.2544s ease;
  -moz-transition: All 0.2544s ease;
  -o-transition: All 0.2544s ease;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #ffcd00;
  background: #364d6a;
}

a[data-toggle="collapse"] {
  position: relative;
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
  content: "\E259";
  display: block;
  position: absolute;
  right: 20px;
  font-family: "Glyphicons Halflings";
  font-size: 0.6em;
}
a[aria-expanded="true"]::before {
  content: "\E260";
}

/* ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
} */

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}
a.download {
  background: #fff;
  color: #7386d5;
}
a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}
/*
 
    NOTIFICACIONES  
----------------------------------------------------- */

.badge3 {
  position: absolute;
  width: 65px;
  height: 65px;
  padding-top: 17px;
  border-radius: 50%;
  top: 20%;
  right: 30px;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.badge3_off {
  position: absolute;
  width: 65px;
  height: 65px;
  padding-top: 17px;
  border-radius: 50%;
  top: 20%;
  right: 30px;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  background-color: #e5e5e5;
}

.badge4 {
  position: absolute;
  width: 40px;
  height: 40px;
  padding-top: 9px;
  border-radius: 50%;
  top: 18%;
  right: 10px;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
}
.top {
  margin-top: 5%;
}
.trash {
  position: absolute;
  margin-top: 32px;
  padding: 13px 15px;
  border-radius: 50%;
  background: #d3140f;
  color: #fff;
}

.trash-return {
  position: absolute;
  margin-top: 50px;
  padding: 13px 15px;
  border-radius: 50%;
  background: #d3140f;
  color: #fff;
}

.badge-notify {
  position: absolute;
  width: 65px;
  height: 65px;
  padding-top: 5px;
  border-radius: 50%;
  /* top: 20%; */
  /* right: 30px; */
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
  /* box-shadow: 0px 7px 15px -5px rgba(0, 0, 0, 0.3); */
  /* background-color: #ffffff; */
}

.badge-img {
  position: absolute;
  width: 65px;
  height: 65px;
  font-size: 2em;
}

.not {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: xx-large;
  color: #ffcd00;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.not_off {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: xx-large;
  color: #bfbfbf;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TERMINAR NOTIFICACIONES */

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
/******* MARGINS & PADDINGS +**********/

/* padding classes */

/* padding 5 */
.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-v-5 {
  padding: 5px 0;
}
.p-h-5 {
  padding: 0 5px;
}

/* padding 10 */
.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-v-10 {
  padding: 10px 0;
}
.p-h-10 {
  padding: 0 10px;
}

/* padding 15 */
.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-v-15 {
  padding: 15px 0;
}
.p-h-15 {
  padding: 0 15px;
}

/* padding 20 */
.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-v-20 {
  padding: 20px 0;
}
.p-h-20 {
  padding: 0 20px;
}

/* padding 25 */
.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-v-25 {
  padding: 25px 0;
}
.p-h-25 {
  padding: 0 25px;
}

/* padding 30 */
.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-v-30 {
  padding: 30px 0;
}
.p-h-30 {
  padding: 0 30px;
}

/* padding 35 */
.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-v-35 {
  padding: 35px 0;
}
.p-h-35 {
  padding: 0 35px;
}

/* padding 40 */
.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-v-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.p-h-40 {
  padding: 0 40px;
}

/* padding 45 */
.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-v-45 {
  padding: 45px 0;
}
.p-h-45 {
  padding: 0 45px;
}

/* padding 50 */
.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-v-50 {
  padding: 50px 0;
}
.p-h-50 {
  padding: 0 50px;
}

/* padding 55 */
.p-55 {
  padding: 55px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-r-55 {
  padding-right: 55px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-l-55 {
  padding-left: 55px;
}
.p-v-55 {
  padding: 55px 0;
}
.p-h-55 {
  padding: 0 55px;
}

/* padding 60 */
.p-60 {
  padding: 60px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-v-60 {
  padding: 60px 0;
}
.p-h-60 {
  padding: 0 60px;
}

/* padding 65 */
.p-65 {
  padding: 65px;
}
.p-t-65 {
  padding-top: 65px;
}
.p-r-65 {
  padding-right: 65px;
}
.p-b-65 {
  padding-bottom: 65px;
}
.p-l-65 {
  padding-left: 65px;
}
.p-v-65 {
  padding: 65px 0;
}
.p-h-65 {
  padding: 0 65px;
}

/* padding 70 */
.p-70 {
  padding: 70px;
}
.p-t-70 {
  padding-top: 70px;
}
.p-r-70 {
  padding-right: 70px;
}
.p-b-70 {
  padding-bottom: 70px;
}
.p-l-70 {
  padding-left: 70px;
}
.p-v-70 {
  padding: 70px 0;
}
.p-h-70 {
  padding: 0 70px;
}

/* padding 75 */
.p-75 {
  padding: 75px;
}
.p-t-75 {
  padding-top: 75px;
}
.p-r-75 {
  padding-right: 75px;
}
.p-b-75 {
  padding-bottom: 75px;
}
.p-l-75 {
  padding-left: 75px;
}
.p-v-75 {
  padding: 75px 0;
}
.p-h-75 {
  padding: 0 75px;
}

/* padding 80 */
.p-80 {
  padding: 80px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-r-80 {
  padding-right: 80px;
}
.p-b-80 {
  padding-bottom: 80px;
}
.p-l-80 {
  padding-left: 80px;
}
.p-v-80 {
  padding: 80px 0;
}
.p-h-80 {
  padding: 0 80px;
}

/* padding 0 */
.no-p {
  padding: 0px;
}
.no-p-t {
  padding-top: 0px;
}
.no-p-r {
  padding-right: 0px;
}
.no-p-b {
  padding-bottom: 0px;
}
.no-p-l {
  padding-left: 0px;
}
.no-p-v {
  padding: 0px 0;
}
.no-p-h {
  padding: 0 0px;
}

/* margin classes */

/* margin 5 */
.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-v-5 {
  margin: 5px 0;
}
.m-h-5 {
  margin: 0 5px;
}

/* margin 10 */
.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-v-10 {
  margin: 10px 0;
}
.m-h-10 {
  margin: 0 10px;
}

/* margin 15 */
.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-v-15 {
  margin: 15px 0;
}
.m-h-15 {
  margin: 0 15px;
}

/* margin 20 */
.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-v-20 {
  margin: 20px 0;
}
.m-h-20 {
  margin: 0 20px;
}

/* margin 25 */
.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-v-25 {
  margin: 25px 0;
}
.m-h-25 {
  margin: 0 25px;
}

/* margin 30 */
.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-v-30 {
  margin: 30px 0;
}
.m-h-30 {
  margin: 0 30px;
}

/* margin 35 */
.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-v-35 {
  margin: 35px 0;
}
.m-h-35 {
  margin: 0 35px;
}

/* margin 40 */
.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-v-40 {
  margin: 40px 0;
}
.m-h-40 {
  margin: 0 40px;
}

/* margin 45 */
.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-v-45 {
  margin: 45px 0;
}
.m-h-45 {
  margin: 0 45px;
}

/* margin 50 */
.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-v-50 {
  margin: 50px 0;
}
.m-h-50 {
  margin: 0 50px;
}

/* margin 55 */
.m-55 {
  margin: 55px;
}
.m-t-55 {
  margin-top: 55px;
}
.m-r-55 {
  margin-right: 55px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-l-55 {
  margin-left: 55px;
}
.m-v-55 {
  margin: 55px 0;
}
.m-h-55 {
  margin: 0 55px;
}

/* margin 60 */
.m-60 {
  margin: 60px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-r-60 {
  margin-right: 60px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-l-60 {
  margin-left: 60px;
}
.m-v-60 {
  margin: 60px 0;
}
.m-h-60 {
  margin: 0 60px;
}

/* margin 65 */
.m-65 {
  margin: 65px;
}
.m-t-65 {
  margin-top: 65px;
}
.m-r-65 {
  margin-right: 65px;
}
.m-b-65 {
  margin-bottom: 65px;
}
.m-l-65 {
  margin-left: 65px;
}
.m-v-65 {
  margin: 65px 0;
}
.m-h-65 {
  margin: 0 65px;
}

/* margin 70 */
.m-70 {
  margin: 70px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-r-70 {
  margin-right: 70px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-l-70 {
  margin-left: 70px;
}
.m-v-70 {
  margin: 70px 0;
}
.m-h-70 {
  margin: 0 70px;
}

/* margin 75 */
.m-75 {
  margin: 75px;
}
.m-t-75 {
  margin-top: 75px;
}
.m-r-75 {
  margin-right: 75px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-l-75 {
  margin-left: 75px;
}
.m-v-75 {
  margin: 75px 0;
}
.m-h-75 {
  margin: 0 75px;
}

/* margin 80 */
.m-80 {
  margin: 80px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-r-80 {
  margin-right: 80px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-l-80 {
  margin-left: 80px;
}
.m-v-80 {
  margin: 80px 0;
}
.m-h-80 {
  margin: 0 80px;
}

/* margin 0 */
.no-m {
  margin: 0px;
}
.no-m-t {
  margin-top: 0px;
}
.no-m-r {
  margin-right: 0px;
}
.no-m-b {
  margin-bottom: 0px;
}
.no-m-l {
  margin-left: 0px;
}
.no-m-v {
  margin: 0px 0;
}
.no-m-h {
  margin: 0 0px;
}

/* static non-generated stuff */
/* margin helpers */
.no-margin {
  margin: 0 !important;
}
.no-m-lr,
.no-m-rl {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-m-tb,
.no-m-bt {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-t-only {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.m-b-only {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}
.m-l-only {
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-r-only {
  margin-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-auto {
  margin-left: auto;
  margin-right: auto;
}

/* padding helpers */
.no-padding {
  padding: 0 !important;
}
.no-pad-lr,
.no-pad-rl {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-pad-tb,
.no-pad-bt {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pad-t-only {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
.pad-b-only {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}
.pad-l-only {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pad-r-only {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.status-validacion p {
  padding-top: 5px;
  padding-left: 10px;
  text-align: center;
}
.status-validacion a {
  color: #2d333b;
}
.status-pendiente {
  background: #f5a623;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  align-content: center;
  padding: 3px;
}
.status-pendiente img {
  width: 19px;
}
#cliente img {
  width: 70%;
}
.rute {
  background: #f8f9fa;
  padding-top: 10px;
  margin-top: -8px;
}
.rute p {
  font-size: 0.7em;
  margin-top: 5px;
  margin-left: -10px;
}
.in-left {
  float: left;
  margin-right: -25px;
}

@media screen and (min-width: 568px) {
  #cliente img {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .visible-xs {
    display: none;
  }
  .visible-lg {
    display: visible;
  }
}
@media (max-width: 1023px) {
  .visible-xs {
    display: visible;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .visible-lg {
    display: none;
  }
}
/* IPHONE 4 LADSCAPE */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .vertical-xs {
    display: none;
  }
  .horizontal-lg {
    display: visible;
  }
}
/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .vertical-xs {
    display: visible;
  }
  .horizontal-lg {
    display: none;
  }
}
/* IPHONE 5 Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .vertical-xs {
    display: none;
  }
  .horizontal-lg {
    display: visible;
  }
}
/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .vertical-xs {
    display: visible;
  }
  .horizontal-lg {
    display: none;
  }
}

/* IPHONE 6 LANDSCAPE */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .vertical-xs {
    display: none;
  }
  .horizontal-lg {
    display: visible;
  }
}
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .vertical-xs {
    display: visible;
  }
  .horizontal-lg {
    display: none;
  }
}

/* IPHONE 6+ Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .vertical-xs {
    display: none;
  }
  .horizontal-lg {
    display: visible;
  }
}
/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .vertical-xs {
    display: visible;
  }
  .horizontal-lg {
    display: none;
  }
}
/* IPHONE X */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  #landscape-device {
    display: visible;
  }
}
@media only screen and (width: 375px) and (height: 635px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  .vertical-xs {
    display: visible;
  }
  .horizontal-lg {
    display: none;
  }
}
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .vertical-xs {
    display: none;
  }
  .horizontal-lg {
    display: visible;
  }
}
/* IPAD */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #landscape-device {
    display: none;
  }
  .visible-xs {
    display: none;
  }
  .visible-lg {
    display: visible;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #landscape-device {
    display: none;
  }

  .vertical-xs {
    display: none;
  }
  .horizontal-lg {
    display: visible;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .vertical-xs {
    display: visible;
  }
  .horizontal-lg {
    display: none;
  }
}

.carousel-content {
  position: absolute;
  z-index: 1000000;
  top: 20px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: #b8e986;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #b8e986;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
ul.timeline > li.warning:before {
  background: #f5a623;
  border: 3px solid #f5a623;
}
ul.timeline > li.danger:before {
  background: #df5062;
  border: 3px solid #df5062;
}
ul.timeline > li p {
  text-transform: uppercase;
}
ul.timeline > li p small {
  text-transform: none;
}
/******************* PROGRESS BAR*******************/
.indicator-alert {
  position: absolute;
  top: -30px;
  display: block;
}
.indicator-alert .text-indicator {
  font-size: 9px;
  text-align: left;
  margin-left: 10px;
}
.indicator-alert .pin {
  width: 15px;
  height: 30px;
  margin-top: 0px;
}
.indicator-alert .wand {
  width: 2px;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
  background-color: #c4c4c4;
}
.indicator-alert .alerta {
  width: 15px;
  height: 15px;
  background: #f4f4f4;
  padding: inherit;
  margin: none;
  border-radius: 100%;
}
.indicator-alert .warning {
  background: #ffcd00;
}
.indicator-alert .danger {
  background: #df5062;
}
.flexer,
.progress-indicator {
  display: flex;
}
.no-flexer,
.progress-indicator.stacked {
  display: block;
}
.no-flexer-element {
  -moz-flex: 0;
  flex: 0 1;
}
.flexer-element,
.progress-indicator > li {
  -moz-flex: 1;
  flex: 1 1;
}
.progress-indicator {
  margin: 0;
  padding: 0;
  font-size: 80%;
  text-transform: uppercase;
  /* margin-bottom: 1em; */
}
.progress-indicator > li {
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  text-overflow: ellipsis;
  color: #bbbbbb;
  display: block;
}
.progress-indicator > li:hover {
  color: #6e6e6e;
}
.progress-indicator > li .bubble i {
  color: #fff;
}
.progress-indicator > li .bubble {
  border-radius: 1000px;
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto 0.5em auto;
}
.progress-indicator > li .bubble:before,
.progress-indicator > li .bubble:after {
  display: block;
  position: absolute;
  top: 9px;
  width: 100%;
  height: 3px;
  content: "";
  z-index: -1;

}
/**/
.progress-bar-icon{
  width: 20px;
  height: 4px;
  display: block;
  margin: 0 auto 0.5em auto;
}

.progress-indicator > li >span> .bubble i {
  color: #fff;
}
.progress-indicator > li >span> .bubble {
  border-radius: 1000px;
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto 0.5em auto;
}
.progress-indicator > li >span> .bubble:before,
.progress-indicator > li >span> .bubble:after {
  display: block;
  position: absolute;
  top: 9px;
  width: 100%;
  height: 3px;
  content: "";
  z-index: -1;

}
/**/
.progress-indicator > li .bubble:before {
  left: 0;
}
.progress-indicator > li .bubble:after {
  right: 0;
}
.progress-indicator > li.completed {
  color: #b8e986;
}
.progress-indicator > li.completed .bubble {
  background-color: #b8e986;
  color: #65d074;
  border-color: #b8e986;
}
.progress-indicator > li.completed .bubble:before,
.progress-indicator > li.completed .bubble:after {
  background-color: #b8e986;
  border-color: #247830;
}
.progress-indicator > li a:hover .bubble {
  background-color: #5671d0;
  color: #5671d0;
  border-color: #1f306e;
}
.progress-indicator > li a:hover .bubble:before,
.progress-indicator > li a:hover .bubble:after {
  background-color: #5671d0;
  border-color: #1f306e;
}
.progress-indicator > li.danger .bubble {
  background-color: #df5062;
  color: #df5062;
  border-color: #df5062;
}
.progress-indicator > li.danger .bubble:before,
.progress-indicator > li.danger .bubble:after {
  /* background-color: #df5062;
  border-color: #440605; */
}
.progress-indicator > li.pi .bubble:before,
.progress-indicator > li.pi .bubble:after {
  /* background-color: #df5062;
  border-color: #440605; */
}
.progress-indicator > li.warning .bubble {
  background-color: #ffcd00;
  color: #ffcd00;
  border-color: #5a4304;
}
.progress-indicator > li.warning .bubble:before,
.progress-indicator > li.warning .bubble:after {
  background-color: #ffcd00;
  border-color: #5a4304;
}
.progress-indicator > li.info .bubble {
  background-color: #0fe9b9;
  color: #0fe9b9;
  border-color: #0fe9b9;
}
.progress-indicator > li.info .bubble:before,
.progress-indicator > li.info .bubble:after {
  background-color: #0fe9b9;
  border-color: #0fe9b9;
}
.progress-indicator > li.completo .bubble {
  background-color: #06f11d;
  color: #06f11d;
  border-color: #25135d;
}
.progress-indicator > li.completo .bubble:before,
.progress-indicator > li.completo .bubble:after {
  background-color: #06f11d;
  border-color: #25135d;
}
.progress-indicator > li.cancelado .bubble {
  background-color: #f10606;
  color: #f10606;
  border-color: #f10606;
}
.progress-indicator > li.cancelado .bubble:before,
.progress-indicator > li.cancelado .bubble:after {
  background-color: #f10606;
  border-color: #f10606;
}
.progress-indicator.stacked > li {
  text-indent: -10px;
  text-align: center;
  display: block;
}
.progress-indicator.stacked > li .bubble:before,
.progress-indicator.stacked > li .bubble:after {
  left: 50%;
  margin-left: -1.5px;
  width: 3px;
  height: 100%;
}
.progress-indicator.stacked .stacked-text {
  position: relative;
  z-index: 10;
  top: 0;
  margin-left: 60% !important;
  width: 45% !important;
  display: inline-block;
  text-align: left;
  line-height: 1.2em;
}
.progress-indicator.stacked > li a {
  border: none;
}
@media handheld, screen and (max-width: 400px) {
  .progress-indicator {
    font-size: 60%;
  }
}

/***===================ICONS ESTATUS===============*********/

.valdacion-process {
  background: #f4f4f4;
  padding: 20px 30px;
  margin: 0px;
}
.ruta-process {
  background: #d6f2e3;
  padding: 20px 30px;
  margin: 0px;
}
.no-autorization-process {
  background: #edc5c0;
  padding: 20px 30px;
  margin: 0px;
}
.autorization-process {
  background: #bddbef;
  padding: 20px 30px;
  margin: 0px;
}
.store-process {
  background: #afe8af;
  padding: 20px 30px;
  margin: 0px;
}

.animated-icon1,
.animated-icon2,
.animated-icon3 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.animated-icon1 span {
  background: #e65100;
}

.animated-icon2 span {
  background: #e3f2fd;
}

.animated-icon3 span {
  background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media (min-width: 900px) {
  .login-card {
    width: 50%;
    margin: 0px auto;
    margin-top: 10%;
    background: #000000;
    padding: 30px 35px;
    border-radius: 10px;
    box-shadow: 0px 25px 25px -10px rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 700px) and (max-width: 900px) {
  .login-card {
    width: 80%;
    margin: 0px auto;
    margin-top: 10%;
    background: #2d333b;
    padding: 30px 35px;
    border-radius: 10px;
    box-shadow: 0px 25px 25px -10px rgba(0, 0, 0, 0.5);
  }
}
@media (max-width: 699px) {
  .login-card {
    width: 90%;
    margin: 0px auto;
    margin-top: 10%;
    background: #2d333b;
    padding: 30px 35px;
    border-radius: 10px;
    box-shadow: 0px 25px 25px -10px rgba(0, 0, 0, 0.5);
  }
}

/* Footer */
footer {
  position: fixed;
  height: 60px;
  bottom: 0;
  width: 100%;
  background: none;
}
footer a {
  background: #fff;
  font-size: 0.6em;
  color: #2d323a;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  border: 0px solid #000000;
}
footer a.active {
  z-index: 10000000;
  background-color: #ffcd00;
  color: #ffffff;
  border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  border: 0px solid #000000;
  transition: All 0.2938s ease;
  -webkit-transition: All 0.2938s ease;
  -moz-transition: All 0.2938s ease;
  -o-transition: All 0.2938s ease;
  box-shadow: -1px -5px 29px -12px rgba(255, 204, 0, 0.35);
}

@media (min-width: 767px) {
  p {
    font-size: 20px;
  }
  .inputWithIcon i {
    position: absolute;
    right: 7%;
    top: 30%;
    padding: 15px 8px;
    color: #2e333b;
    transition: 0.3s;
  }
  span.badge-success {
    margin: -17px;
    margin-top: 20px;
    padding: 19px 30px 33px 22px;
    border-radius: 6% 0% 0% 6%;
    background: #b8e986;
  }
  .badge2 {
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 2px 0px 0px 1px;
    border-radius: 50%;
    top: 11%;
    right: 30%;
    font-size: 2em;
    font-weight: 700;
    text-align: center;
  }
  .unidad {
    background: #fff;
    margin: 8px 0 8px 0;
    font-size: 1em;
    height: 70px;
    width: 100%;
    margin-bottom: 0.3em;
    padding: 9px 0px;
    border-radius: 7px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
  }
  span.badge-pending {
    margin: -17px;
    margin-top: 20px;
    padding: 20px 7px 33px 0px;
    border-radius: 6% 0% 0% 6%;
    background: #ffcd00;
  }
  span.badge-danger- {
    margin: -15px;
    margin-top: 20px;
    padding: 23px 7px 28px 0px;
    border-radius: 6% 0% 0% 6%;
    background: #dc3545;
  }
  .btn {
    text-transform: uppercase;
    font-size: 0.9em;
    /* padding: 3% 11%; */
    border-radius: 3px;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 51px;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  transition: 0.3s;
}

.form-alert {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  background-color: #dc35451f;
  box-shadow: 0 0px 5px 0 #dc3545;
  border-radius: 0.25rem;
  transition: 0.3s;
}

.alert-form {
  color: #dc3545;
  font-size: 1rem;
}

.alert-warning {
  color: #000000;
  background-color: #f0f2f2;
  margin-top: 1%;
  border: #f0f2f2;
}

/* Menu stilos */
/*
  Menu


/*
  Menu
*/
nav#slide-menu {
  position: fixed;
  top: 0;
  left: -100px;
  bottom: 0;
  display: block;
  float: left;
  width: 100%;
  max-width: 284px;
  height: 100%;
  transition: all 300ms;
}

nav#slide-menu > ul {
  display: block;
  margin: 40px;
  padding: 0;
  list-style: none;
  opacity: 0.5;
  transition: all 300ms transform;
}

nav#slide-menu > ul li {
  color: #dfe5eb;
  padding: 6px 0;
  cursor: pointer;
}

nav#slide-menu > ul li:hover {
  color: black;
}

nav#slide-menu > ul li:before {
  font-family: "ico";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  color: #ffcd00;
  margin-right: 12px;
  text-align: center;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-size: 0.75em;
  text-transform: none;
  line-height: 1em;
}

nav#slide-menu > ul li.sep {
  margin-top: 7px;
  padding-top: 14px;
  border-top: 1px solid #6b7b8d;
}

nav#slide-menu > ul li.timeline:before {
  content: "\E805";
}

nav#slide-menu > ul li.events:before {
  content: "\E802";
}

nav#slide-menu > ul li.calendar:before {
  content: "\E800";
}

nav#slide-menu > ul li.settings:before {
  content: "\E803";
}

nav#slide-menu > ul li.logout:before {
  content: "\E804";
}

body.menu-active nav#slide-menu {
  background-color: #2e333b;
  left: 0px;
}

body.menu-active nav#slide-menu ul {
  left: 0px;
  opacity: 1;
}

/*
  Content
*/

a {
  color: #f0f2f2;
}

div#content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: #fcfeff;
  border-radius: 0;
  box-sizing: border-box;
  /* -moz-box-shadow: -3px 0 6px #4a5562;
  -webkit-box-shadow: -3px 0 6px #4a5562; */
  /* box-shadow: -3px 0 6px #eaeaea; */
  transition: all 300ms;
}

div#content div.menu-trigger {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 36px;
  height: 36px;
  background: #dfe5eb;
  cursor: pointer;
  border-radius: 5px;
  transition: all 300ms;
}

div#content div.menu-trigger:before {
  content: "\E801";
  display: inline-block;
  width: 36px;
  height: 36px;
  color: black;
  font-style: normal;
  font-size: 1.2em;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-align: center;
  text-transform: none;
  line-height: 36px;
}

div#content div.menu-trigger:hover {
  background: #556270;
}

div#content div.menu-trigger:hover:before {
  color: #fcfeff;
}

body.menu-active div#content {
  left: 284px;
  border-radius: 0px 0 0 0px;
}

body.menu-active div#content .menu-trigger {
  left: 294px;
}

/* collapse */

.panel {
  margin-bottom: 0px;
}

.checkout-step {
  /* background: #e8eef1; */
  background: #fff;
  background: -webkit-linear-gradient(to right, #b6fbff, #83a4d4);
  /* background: linear-gradient(to right, #b6fbff, #83a4d4); */
  border-top: 1px solid #607d8b21;
  /* color: #666; */
  /* font-size: 14px; */
  /* position: relative; */
}

.checkout-step-number {
  border-radius: 50%;
  /* border: 1px solid #ced0d2; */
  display: inline-block;
  background: #ffcd00;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  margin-right: 15px;
  padding: 6px;
  text-align: center;
  width: 50px;
  height: 50px;
}

.checkout-step-title {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
  margin: 0px;
  color: #3d4884;
}

.checkout-step-body {
  background: #fbfbfb;
  padding: 15px 0px;
  margin: 20px 0px 0px;
}

/*Shyam*/

.imi-joingform {
  margin-top: 50px;
}

.imc-jfheader {
  background: #fff;
  padding: 15px;
}

.imc-text h1 {
  font-size: 20px;
  color: #00bcd4;
}

.imc-text h2 {
  font-size: 12px;
}

.imc-jfeditbtn {
  padding: 5px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  background: #29506f;
}
.badge_1 {
  background: #ffcd00;
}
.btn-default {
  background: #fff;
  color: #2e333b;
  border-color: #2e333b;
}
.btn-default-g {
  background: #f0f2f2;
  color: #2e333b;
  border-color: #b8b8b8;
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger-b {
  color: #dc3545;
  background-color: #f0f2f2;
  border-color: #dc3545;
}
.btn-block {
  display: block;
  width: 90%;
  text-align: center !important;
  margin-left: 5%;
  border-radius: 50px !important;
}
.ibox-tools {
  float: none;
  text-align: right;
  display: block;
}
.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: relative;
  padding: 0;
  text-align: right;
}
.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.wrap {
  display: flex;
  padding: 0.3rem 0.3rem 0.3rem 0.3rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.wrap {
  background: linear-gradient(135deg, #ff6363 0%, #dc3545 100%);
  color: white;
}

.ico-wrap {
  margin: auto;
}

.mbr-iconfont {
  font-size: 4.5rem !important;
  color: #313131;
  margin: 1rem;
  padding-right: 1rem;
}
.vcenter {
  margin: auto;
}

.mbr-section-title3 {
  text-align: left;
}
h2 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.display-5 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.4rem;
}
.mbr-bold {
  font-weight: 700;
}

p {
  margin-top: 0;
  margin-bottom: orem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  line-height: 20px;
}
.display-6 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1re;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  /* padding-left: 0px !important;
  padding-right: 0px !important; */
  /* --bs-gutter-x: 0rem; */
}
.btn-block- {
  display: block;
  width: 100%;
  text-align: center;
}
.wrapper-content {
  padding: 20px 10px 40px;
}
/* modal */

/****** LOGIN MODAL ******/
.loginmodal-container {
  padding: 30px;
  max-width: 350px;
  width: 100% !important;
  background-color: #f7f7f7;
  margin: 0 auto;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-family: roboto;
}

.loginmodal-container h1 {
  text-align: center;
  font-size: 1.8em;
  font-family: roboto;
}

.loginmodal-container input[type="submit"] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.loginmodal-container input[type="text"],
input[type="password"] {
  height: 50px;
  font-size: 1em;
  width: 100%;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.loginmodal-container input[type="text"]:hover,
input[type="password"]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.loginmodal {
  text-align: center;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  height: 36px;
  padding: 0 8px;
  /* border-radius: 3px; */
  /* -webkit-user-select: none;
  user-select: none; */
}

.loginmodal-submit {
  /* border: 1px solid #3079ed; */
  border: 0px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
  background-color: #4d90fe;
  padding: 17px 0px;
  font-family: roboto;
  font-size: 14px;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#4787ed)); */
}

.loginmodal-submit:hover {
  /* border: 1px solid #2f5bb7; */
  border: 0px;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  background-color: #357ae8;
  /* background-image: -webkit-gradient(linear, 0 0, 0 100%,   from(#4d90fe), to(#357ae8)); */
}

.loginmodal-container a {
  text-decoration: none;
  color: #666;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  opacity: 0.6;
  transition: opacity ease 0.5s;
}

.login-help {
  font-size: 12px;
}
.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #e4e4e4;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
}
.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0%;
  padding: 0;
}
.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}
.ibox-content- {
  background-color: #ffffff;
  color: inherit;
  padding: 0px 0px 0px 0px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 20px 10px 10px 10px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.ibox-content {
  clear: both;
}
.ibox.fullscreen .collapse-link {
  display: none;
}
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #c4c4c4;
}
.timeline-item .date-info {
  text-align: left;
  width: 75px;
  position: relative;
  padding-top: 45px;
  padding: 45px 0px 0px 0px;
}
.timeline-item .content {
  border-left: 1px solid #e7eaec;
  border-top: 1px solid #e7eaec;
  padding-top: 10px;
  min-height: 100px;
  padding-left: 15px;
}
.timeline-item .date-primary {
  text-align: left;
  width: 75px;
  position: relative;
  padding-top: 45px;
  padding: 45px 0px 0px 0px;
}
.timeline-item .date-primary i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  border-left: 1px solid #e7eaec;
  background: #ffcd00;
}
.timeline-item .date-danger {
  text-align: left;
  width: 75px;
  position: relative;
  padding-top: 45px;
  padding: 45px 0px 0px 0px;
}
.timeline-item .date-danger i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  border-left: 1px solid #e7eaec;
  background: #df5062;
}
.timeline-item .date i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  border-left: 1px solid #e7eaec;
  background: #f8f8f8;
}
.no-top-border {
  border-top: 0 !important;
}
.timeline-item .date-info i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 30px;
  text-align: center;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  border-left: 1px solid #e7eaec;
  background: #a2e461;
}
.timeline-item .date {
  text-align: left;
  width: 75px;
  position: relative;
  padding-top: 45px;
  padding: 45px 0px 0px 0px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.row-time {
  margin-right: -15px;
  margin-left: -15px;
}
.white-bg {
  background-color: #ffffff;
}
.page-heading {
  border-top: 0;
  padding: 0 10px 0px 10px;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0px;
}
.product-desc {
  padding: 20px;
  position: relative;
}

.cart-product-imitation {
  text-align: center;
  padding-top: 30px;
  height: 80px;
  width: 80px;
  background-color: #f8f8f9;
}
.product-imitation.xl {
  padding: 120px 0;
}
.product-desc {
  padding: 20px;
  position: relative;
}
.ecommerce .tag-list {
  padding: 0;
}
.col-xs-4 {
  width: 33.33333333%;
  float: left;
  text-align: center;
}
.m-t-xs {
  margin-top: 5px;
}
.incidentes {
  color: #ffcd00;
  font-weight: 900;
}
.alertas {
  color: #df5062;
  font-weight: 900;
}
.exitos {
  color: #b8e986;
  font-weight: 900;
}
.text-info {
  /* color: #b8e986 !important; */
  margin-top: 5px;
  text-decoration: none;
}
.text-primary {
  color: #ffcd00 !important;
  margin-top: 5px;
}
.text-danger {
  color: #df5062 !important;
  margin-top: 5px;
}
.text-sucess {
  color: #676a6c !important;
  margin-top: 5px;
}
.notify {
  background: #fff;
  margin: 1px 0 1px 0;
  font-size: 1em;
  height: 90px;
  width: 100%;
  margin-bottom: 0.3em;
  padding: 9px 0px;
  border-radius: 7px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14);
}
.scroll_ {
  height: 850px;
  overflow: auto;
}
.clearfix {
  overflow: auto;
}
.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}
.cursor {
  cursor: pointer;
  text-decoration: none;
}

/* check */

.funkyradio div {
  clear: both;
  overflow: hidden;
}

.funkyradio label {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d1d3d4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty,
.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label,
.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.2em;
  text-indent: 2.3em;
  margin-top: 0.3em;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before,
.funkyradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  width: 2.5em;
  background: #d1d3d4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,
.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: "\2714";
  text-indent: 0.9em;
  color: #c2c2c2;
}

.funkyradio input[type="radio"]:checked ~ label,
.funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="radio"]:checked ~ label:before,
.funkyradio input[type="checkbox"]:checked ~ label:before {
  content: "\2714";
  text-indent: 0.2em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before,
.funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-default input[type="radio"]:checked ~ label:before,
.funkyradio-default input[type="checkbox"]:checked ~ label:before {
  color: #333;
  background-color: #ccc;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before,
.funkyradio-primary input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #337ab7;
}

.funkyradio-success input[type="radio"]:checked ~ label:before,
.funkyradio-success input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5cb85c;
}

.funkyradio-danger input[type="radio"]:checked ~ label:before,
.funkyradio-danger input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #df5062;
}

.funkyradio-warning input[type="radio"]:checked ~ label:before,
.funkyradio-warning input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #ffcd00;
}

.funkyradio-info input[type="radio"]:checked ~ label:before,
.funkyradio-info input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #b8e986;
}
.switch input {
  display: none;
}
.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 8px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #777;
  border-radius: 30px;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
  background: limeGreen;
}
input:checked + .slider {
  box-shadow: 0 0 0 2px limeGreen, 0 0 2px limeGreen;
}
.section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f7f7f7;
  background-clip: border-box;
  border-radius: 0.25rem;
  margin: 0px 0px 10px 0px;
}

/* Pantalla error */

/**/
:root {
  --main-color: #eaeaea;
  --stroke-color: black;
}
/**/

h1 {
  margin: 100px auto 0 auto;
  color: black;
  color: var(--stroke-color);
  /* font-family: "Encode Sans Semi Condensed", Verdana, sans-serif; */
  font-size: 10rem;
  line-height: 10rem;
  font-weight: 200;
  text-align: center;
}
h2 {
  /* margin: 20px auto 30px auto; */
  /* font-family: "Encode Sans Semi Condensed", Verdana, sans-serif; */
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
}
h1,
h2 { /* Safari */
  transition: opacity 0.5s linear, margin-top 0.5s linear;
}
.loading h1,
.loading h2 {
  margin-top: 0px;
  opacity: 0;
}
.gears {
  position: relative;
  margin: 0 auto;
  width: auto;
  height: 0;
}
.gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: black;
  background: var(--stroke-color);
}
.gear:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  content: "";
  border-radius: 50%;
  background: #eaeaea;
  background: var(--main-color);
}
.gear:after {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 3;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid black;
  border: 5px solid var(--stroke-color);
  box-sizing: border-box;
  background: #eaeaea;
  background: var(--main-color);
}
.gear.one {
  left: -130px;
}
.gear.two {
  top: -75px;
}
.gear.three {
  top: -235px;
  left: 130px;
}
.gear .bar {
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 0;
  width: 150px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: black;
  background: var(--stroke-color);
}
.gear .bar:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  content: "";
  border-radius: 2px;
  background: #eaeaea;
  background: var(--main-color);
}
.gear .bar:nth-child(2) {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}
.gear .bar:nth-child(3) {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
}
@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes anticlockwise {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes clockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(30deg);
  }
  40% {
    -webkit-transform: rotate(25deg);
  }
  60% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes anticlockwiseErrorStop {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-30deg);
  }
  60% {
    -webkit-transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-webkit-keyframes anticlockwiseError {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(-30deg);
  }
  40% {
    -webkit-transform: rotate(-25deg);
  }
  60% {
    -webkit-transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
.gear.one {
  -webkit-animation: anticlockwiseErrorStop 2s linear infinite;
}
.gear.two {
  -webkit-animation: anticlockwiseError 2s linear infinite;
}
.gear.three {
  -webkit-animation: clockwiseError 2s linear infinite;
}
.loading .gear.one,
.loading .gear.three {
  -webkit-animation: clockwise 3s linear infinite;
}
.loading .gear.two {
  -webkit-animation: anticlockwise 3s linear infinite;
}

.cf {
  *zoom: 1;
}

.cf:after {
  clear: both;
}
.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.sidebar {
  float: left;
  position: -webkit-sticky;
  position: sticky;
}

/* pass show */
.pass_show {
  position: relative;
}

.pass_show .ptxt {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  color: #929292;
  margin-top: -10px;
  cursor: pointer;
  transition: 0.3s ease all;
}

.pass_show .ptxt:hover {
  color: #333333;
}
/* pass show end */

.img-responsive {
  margin-top: 50%;
}

/* --------------------- */
.compact {
  max-height: 25px;
  overflow: hidden;
}

.ctrl {
  width: 100%;
  position: relative;
}

/* Animación span unidades guardadas */
.nortification {
  display: block;
  font-size: 14px;
  width: 200px;
  padding: 5px 0;
  position: absolute;
  top: -60px;
  left: 50%;
  margin-left: -100px;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #fff;
  color: #dd3545;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.2);
}

/* Safari and Chrome */
@-webkit-keyframes moveOpen {
  from {
    -webkit-transform: translate(0, -40px);
  }
  10% {
    -webkit-transform: translate(0, 20px);
  }
  12% {
    -webkit-transform: translate(0, 22px);
  }
  16% {
    -webkit-transform: translate(0, 20px);
  }
  80% {
    -webkit-transform: translate(0, 20px);
  }
  85% {
    -webkit-transform: translate(0, 25px);
  }
  to {
    -webkit-transform: translate(0, -40px);
  }
}
.btn-form{
  background-color: var(--primary-color);
  color:var(--secondary-color);
  padding: 1rem;
  width: 100%;
}
.btn-form:hover{
  background-color: var(--secondary-color);
  color:var(--primary-color);
  padding: 1rem;
  width: 100%;
}
.error{
    color: red;
}


.login-card form .alert-danger {
    text-align: center;
}

#contenedorErrores{
    width: 100%;
    height: 50px;
    margin-top: 40px;
}
.newsletter-subscribe {
    color:#313437;
    background-color:#fff;
    padding:50px 0;
  }
  
  .newsletter-subscribe p {
    color:#7d8285;
    line-height:1.5;
  }
  
  .newsletter-subscribe h2 {
    font-size:24px;
    font-weight:bold;
    margin-bottom:25px;
    line-height:1.5;
    padding-top:0;
    margin-top:0;
    color:inherit;
  }
  
  .newsletter-subscribe .intro {
    font-size:16px;
    max-width:500px;
    margin:0 auto 25px;
  }
  
  .newsletter-subscribe .intro p {
    margin-bottom:35px;
  }
  
  .newsletter-subscribe form {
    justify-content:center;
  }
  
  .newsletter-subscribe form .form-control {
    background:#eff1f4;
    border:none;
    border-radius:3px;
    box-shadow:none;
    outline:none;
    color:inherit;
    text-indent:9px;
    height:45px;
    margin-right:10px;
    min-width:250px;
  }
  
  .newsletter-subscribe form .btn {
    padding:16px 32px;
    border:none;
    background:none;
    box-shadow:none;
    text-shadow:none;
    opacity:0.9;
    text-transform:uppercase;
    font-weight:bold;
    font-size:13px;
    letter-spacing:0.4px;
    line-height:1;
  }
  
  .newsletter-subscribe form .btn:hover {
    opacity:1;
  }
  
  .newsletter-subscribe form .btn:active {
    -webkit-transform:translateY(1px);
            transform:translateY(1px);
  }
  
  .newsletter-subscribe form .btn-primary {
    background-color:#055ada !important;
    color:#fff;
    outline:none !important;
  }
  
  
